@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .main-container {
    @apply mx-auto w-full max-w-[1360px] px-4 sm:px-6;
  }
  .container-full-width {
    max-width: 100%;
    @apply px-0;
  }

  .bg-linear-gradient-banner {
    background: linear-gradient(
      54deg,
      theme('colors.deep-blue') 0%,
      theme('colors.dark-blue') 37.11%,
      theme('colors.milestone-blue') 67.66%
    );
  }
  .bg-light-blue-linear-gradient-banner {
    background: linear-gradient(
      54deg,
      theme('colors.soft-grey') 0%,
      theme('colors.dust') 37.11%,
      theme('colors.light-blue') 67.66%
    );
  }

  .anchor {
    @apply font-semibold underline hover:!text-milestone-blue;
  }

  /* a.NavLink-link {
    color: theme('colors.black');
  } */
}

/* CSS RESET */
img {
  height: auto;
}
a {
  text-decoration: none;
}
a:not(.MuiButton-root):not(.Milestone-link) {
  color: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* END CSS RESET */

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --text-primary: theme('colors.neutral.900');
}

body,
main {
  /* color: rgb(var(--foreground-rgb)); */
  color: var(--text-primary);
  background: theme('colors.white');
}

.disabled:hover {
  @apply !text-light-grey;
}

.markdown p:not(:first-child) {
  @apply !mt-5;
}

.markdown-legal h2 {
  margin-top: 20px !important;
}

.cms-inline-link {
  display: inline;
  p {
    display: inline;
  }

  &:before {
    content: ' ';
    display: inline-block;
    width: 0.25rem;
  }
}
